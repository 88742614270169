import React from "react";

import { useTranslation, Trans } from "react-i18next";

import notebook from '../../img/Home/img.png'
import img from '../../img/Home/bg.png'

import Fade from "react-reveal/Fade";

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="h-[937px] overflow-hidden relative">
            <div className="flex flex-col xl:flex-row max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="mt-[100px] xl:mt-[170px] z-50 relative">
                    <p className="bebas text-[32px] xl:text-[90px] leading-[1.2]"><Trans>{t('main.1')}</Trans></p>
                    <p className="roboto text-[24px] xl:text-[40px] leading-[1.2] right-[-100px] top-[100px] mt-[12px] xl:mt-0"><Trans>{t('main.2')}</Trans></p>
                    <a href="https://user.ameno-platform.org/login" >
                        <div className="button_main mt-[40px] xl:mt-[55px]">
                            <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
                <div>
                    <img src={img} alt="" className="absolute right-0 hidden xl:block"/>
                    <Fade right><img src={notebook} alt="" className="absolute right-0 top-[200px] xl:top-0"/></Fade>
                </div>
            </div>
            <div className="mx-[20px] flex flex-wrap gap-x-[50px] xl:gap-[100px] items-center justify-center xl:w-[1280px] xl:h-[134px] h-[224px] bg-white rounded-[40px] z-50 relative xl:mx-auto mt-[190px]">
                <div className="flex flex-col items-center">
                    <p className="text-black bebas text-[32px] xl:text-[48px] leading-[1.2]">{t('main.4')}</p>
                    <p className="roboto text-[16px] xl:text-[24px] leading-[1.2]">{t('main.5')}</p>
                </div>
                <div className="flex flex-col items-center">
                    <p className="text-black bebas text-[32px] xl:text-[48px] leading-[1.2]">{t('main.6')}</p>
                    <p className="roboto text-[16px] xl:text-[24px] leading-[1.2]">{t('main.7')}</p>
                </div>
                <div className="flex flex-col items-center">
                    <p className="text-black bebas text-[32px] xl:text-[48px] leading-[1.2]">{t('main.8')}</p>
                    <p className="roboto text-[16px] xl:text-[24px] leading-[1.2]">{t('main.9')}</p>
                </div>
            </div>
        </div>
    );
}

export default Main;