import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import MobileMenu from "./MobileMenu";
import arrow from '../img/Vector.png'

function Header() {

    const { t } = useTranslation();

    const location = useLocation();

    const [isProductsOpen, setIsProductsOpen] = useState(false);

    const toggleProductsMenu = () => {
        setIsProductsOpen(!isProductsOpen);
    };


    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'tr', label: 'TR' },
        { value: 'pl', label: 'PL' },
        { value: 'fr', label: 'FR' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="flex justify-center">
            <div className="hidden fixed w-full h-[60px] mt-[10px] bg-[#000] z-[999] mx-auto xl:flex items-center max-w-[1280px] rounded-[80px]">
                <div className="flex gap-[60px] items-center">
                    <Link to="/"><img src={logo} alt="" className="ml-[20px] w-[70px]"/></Link>
                    <div className="flex cursor-pointer items-center" onClick={toggleProductsMenu}>
                        <p className="text-[16px] text-[#FFFFFFB2] roboto">{t('header.1')}</p>
                        <img src={arrow} alt="" className={isProductsOpen ? "ml-[6px] transform rotate-180 w-[11px] h-[5px] transition-all" : "transition-all ml-[6px] w-[11px] h-[5px]"} />
                        {isProductsOpen && (
                            <div className="absolute w-[280px] h-[106px] bg-[#000] rounded-[15px] top-[65px]">
                                <div className="flex justify-between mx-[30px] my-[20px]">
                                    <div className="flex flex-col gap-[15px]">
                                        <Link to="/investing"><p className={`text-[16px] font-light open-sans ${location.pathname === '/investing' ? 'text-[#00D1FF]' : 'text-[#FFFFFFB2]'}`}>{t('header.m.1')}</p></Link>
                                        <Link to="/crypto"><p className={`text-[16px] font-light open-sans ${location.pathname === '/crypto' ? 'text-[#00D1FF]' : 'text-[#FFFFFFB2]'}`}>{t('header.m.2')}</p></Link>
                                    </div>
                                    <div className="flex flex-col gap-[15px]">
                                    <Link to="/iras"><p className={`text-[16px] font-light open-sans ${location.pathname === '/iras' ? 'text-[#00D1FF]' : 'text-[#FFFFFFB2]'}`}>{t('header.m.3')}</p></Link>
                                        <Link to="/banking"><p className={`text-[16px] font-light open-sans ${location.pathname === '/banking' ? 'text-[#00D1FF]' : 'text-[#FFFFFFB2]'}`}>{t('header.m.4')}</p></Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <Link to="/referral"><p className="text-[16px] roboto text-[#FFFFFFB2]">{t('header.2')}</p></Link>
                    <Link to="/accounts"><p className="text-[16px] roboto text-[#FFFFFFB2]">{t('header.3')}</p></Link>
                    <Link to="/about"><p className="text-[16px] roboto text-[#FFFFFFB2]">{t('header.4')}</p></Link>
                </div>           
                <div className="ml-auto flex items-center gap-[50px] mr-[20px]">
                    <Dropdown
                        
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    />
                    <Link to="https://user.ameno-platform.org/login" >
                        <div className="button cursor-pointer">
                            <p className="font-[400] text-[17px] roboto text-black uppercase">{t('header.5')}</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="xl:hidden w-full flex fixed z-[999] h-[60px] items-center justify-between bg-[#262626]">
                <Link to="/"><img src={logo} alt="" className="ml-[20px] w-[60px]"/></Link>
                <div className="flex items-center gap-[10px]">
                    <Dropdown
                        
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    />
                    <MobileMenu className="mr-[20px]"/>
                </div>      
            </div>
        </div>
    );
}

export default Header;