import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/ReferralProgram/main.png'

import Fade from "react-reveal/Fade";

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="overflow-hidden relative">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between">
                <div className="mt-[160px]">
                    <p className="bebas text-[40px] xl:text-[90px] leading-[1.2] uppercase"><Trans>{t('referralprogram.1')}</Trans></p>
                    <p className="mx-auto max-w-[600px] text-[14px] xl:text-[16px] roboto font-[300] text-[#000000B2] mt-[20px] z-50">{t('referralprogram.2')}</p>
                    <p className="mx-auto max-w-[600px] text-[14px] xl:text-[16px] roboto font-[300] text-[#000000B2] mt-[20px] z-50">{t('referralprogram.3')}</p>
                    <a href="https://user.ameno-platform.org/login">
                        <div className="button_main mt-[40px] mx-auto z-50">
                            <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
                <div>
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>
                </div>
            </div>
            
        </div>
    );
}

export default Main;