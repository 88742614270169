import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Crypto/main.png'
import check from '../../img/Crypto/check.png'

import Fade from "react-reveal/Fade";

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="overflow-hidden relative">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between mt-[80px]">
                <div className="mt-[100px]">
                    <p className="bebas text-[40px] xl:text-[90px] leading-[1.2]">{t('crypto.1')}</p>
                    <p className="roboto text-[24px] xl:text-[40px] leading-[1.2]">{t('crypto.2')}</p>
                    <p className="mt-[20px] roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2]">{t('crypto.3')}</p>
                    <a href="https://user.ameno-platform.org/login">
                        <div className="button_main mt-[40px]">
                            <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                        </div>
                    </a>
                    <div className="flex gap-[30px] mt-[30px] xl:mt-[80px] items-start">
                        <img src={check} alt="" />
                        <p className="roboto text-[16px] xl:text-[24px]">{t('crypto.4')}</p>
                    </div>
                    <div className="flex gap-[30px] mt-[20px] items-start">
                        <img src={check} alt="" />
                        <p className="roboto text-[16px] xl:text-[24px]">{t('crypto.5')}</p>
                    </div>
                    <div className="flex gap-[30px] mt-[20px] items-start">
                        <img src={check} alt="" />
                        <p className="roboto text-[16px] xl:text-[24px]">{t('crypto.6')}</p>
                    </div>
                </div>
                <div>
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
                <p className="roboto text-[24px] xl:text-[40px] leading-[1.4] max-w-[467px]">{t('crypto.7')}</p>
                <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px] gap-y-[20px] xl:gap-y-0">
                    <div className="w-[393px] h-[317px] rounded-[40px] bg-white flex flex-col justify-center p-[40px]">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('crypto.8')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto font-[300] text-[#000000B2] mt-[16px]">{t('crypto.9')}</p>
                    </div>
                    <div className="w-[393px] h-[317px] rounded-[40px] bg-white flex flex-col justify-center p-[40px]">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('crypto.10')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto font-[300] text-[#000000B2] mt-[16px]">{t('crypto.11')}</p>
                    </div>
                    <div className="w-[393px] h-[317px] rounded-[40px] bg-white flex flex-col justify-center p-[40px]">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('crypto.12')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto font-[300] text-[#000000B2] mt-[16px]">{t('crypto.13')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;